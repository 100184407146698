import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const StudioFullwidth = () => {
  return (
    <section className="rivian-full-width py-0">        
        <Container fluid>
            <Row>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/studio-34/studio-34-branding-1.jpg" alt="Studio 34 Brand Identity Presentation 1 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/studio-34/studio-34-branding-2.jpg" alt="Studio 34 Brand Identity Presentation 2 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>                
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/studio-34/studio-34-branding-3.jpg" alt="Studio 34 Brand Identity Presentation 3 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/studio-34/studio-34-branding-4.jpg" alt="Studio 34 Brand Identity Presentation 4 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/studio-34/studio-34-branding-5.jpg" alt="Studio 34 Brand Identity Presentation 5 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/studio-34/studio-34-branding-6.jpg" alt="Studio 34 Brand Identity Presentation 6 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/studio-34/studio-34-branding-7.jpg" alt="Studio 34 Brand Identity Presentation 7 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/studio-34/studio-34-branding-8.jpg" alt="Studio 34 Brand Identity Presentation 8 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/studio-34/studio-34-branding-9.jpg" alt="Studio 34 Brand Identity Presentation 9 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/studio-34/studio-34-branding-10.jpg" alt="Studio 34 Brand Identity Presentation 10 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/studio-34/studio-34-branding-11.jpg" alt="Studio 34 Brand Identity Presentation 11 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/studio-34/studio-34-branding-12.jpg" alt="Studio 34 Brand Identity Presentation 12 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/studio-34/studio-34-branding-13.jpg" alt="Studio 34 Brand Identity Presentation 13 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/studio-34/studio-34-branding-14.jpg" alt="Studio 34 Brand Identity Presentation 14 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/studio-34/studio-34-branding-15.jpg" alt="Studio 34 Brand Identity Presentation 15 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/studio-34/studio-34-branding-16.jpg" alt="Studio 34 Brand Identity Presentation 16 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/studio-34/studio-34-branding-17.jpg" alt="Studio 34 Brand Identity Presentation 17 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/studio-34/studio-34-branding-18.jpg" alt="Studio 34 Brand Identity Presentation 18 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/studio-34/studio-34-branding-19.jpg" alt="Studio 34 Brand Identity Presentation 19 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>                                                                                                                                
            </Row>
        </Container>
    </section>
  )
}

export default StudioFullwidth