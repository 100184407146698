import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/branding/studio-34.scss"

import brandingFeaturedImage from "../../../static/branding/studio-34/studio-34-featured-Image.png"
import StudioMain from "../../components/branding/studio-34/studioMain"
import StudioFullwidth from "../../components/branding/studio-34/studioFullWidth"
import StudioMoreBranding from "../../components/branding/studio-34/studioMoreBranding"

export const Head = () => (
    <>
        <SeoHead 
            ogImage={brandingFeaturedImage}
            title="Studio 34: Brand Identity Revolution | WowMakers"
            description="Witness how WowMakers unleashed Studio 34's creative potential through strategic branding and bold design choices."
        />
    </>
)

const BlueMermaidEventsBranding = () => {
    
    return (
        <Layout>
            <Seo
                bid="branding-studio-34"
                bclass="case-studies-page branding"
            ></Seo>
            <ScrollIndicationHeightZero />
            <StudioMain />
            <StudioFullwidth />
            <StudioMoreBranding />
        </Layout>
    )
}

export default BlueMermaidEventsBranding